import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import addFileIcon from "./image/addFileIcon.svg";
import Error from "./utility/Error";
import * as tf from "@tensorflow/tfjs";
import { CLASSES } from "./labels.js";
import landingImg from "./image/landingimg.jpg"; // resize image to 128*128 px to fit the card
import { Audio } from "react-loader-spinner";

function DragNDrop() {
  const [files, setFiles] = useState([]);
  const [detectnum, setDetectnum] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/png": [".png", ".jpg", ".jpeg"],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          )
        );
      },
    });
  //ai
  async function performDetections() {
    setIsLoading(true);
    await tf.ready();
    const modelPath =
      "https://tfhub.dev/tensorflow/tfjs-model/ssd_mobilenet_v2/1/default/1";

    const model = await tf.loadGraphModel(modelPath, { fromTFHub: true });
    const mysteryImage = document.getElementById("gant");
    setIsLoading(false);
    const myTensor = files && tf.browser.fromPixels(mysteryImage);
    // SSD Mobilenet single batch
    const readyfied = files && tf.expandDims(myTensor, 0);
    const results = files && (await model.executeAsync(readyfied));
    // files && console.log(results[0].shape);

    // Prep Canvas
    const detection = document.getElementById("detection");
    const ctx = detection.getContext("2d");
    const imgWidth = mysteryImage.width;
    const imgHeight = mysteryImage.height;
    detection.width = imgWidth;
    detection.height = imgHeight;
    ctx.font = "16px sans-serif";
    ctx.textBaseline = "top";

    // Get a clean tensor of top indices
    const detectionThreshold = 0.4;
    const iouThreshold = 0.5;
    const maxBoxes = 20;
    const prominentDetection = files && tf.topk(results[0]);
    const justBoxes = files && results[1].squeeze();
    const justValues = files && prominentDetection.values.squeeze();

    // Move results back to JavaScript in parallel
    const [maxIndices, scores, boxes] =
      files &&
      (await Promise.all([
        prominentDetection.indices.data(),
        justValues.array(),
        justBoxes.array(),
      ]));

    // https://arxiv.org/pdf/1704.04503.pdf, use Async to keep visuals
    const nmsDetections =
      files &&
      (await tf.image.nonMaxSuppressionWithScoreAsync(
        justBoxes, // [numBoxes, 4]
        justValues, // [numBoxes]
        maxBoxes,
        iouThreshold,
        detectionThreshold,
        1 // 0 is normal NMS, 1 is Soft-NMS for overlapping support
      ));

    const chosen = files && (await nmsDetections.selectedIndices.data());
    // console.log("detected nums", chosen.length);
    setDetectnum(chosen.length);
    // Mega Clean

    tf.dispose([
      results[0],
      results[1],
      model,
      nmsDetections.selectedIndices,
      nmsDetections.selectedScores,
      prominentDetection.indices,
      prominentDetection.values,
      myTensor,
      readyfied,
      justBoxes,
      justValues,
    ]);

    files &&
      chosen.forEach((detection) => {
        ctx.strokeStyle = "#0F0";
        ctx.lineWidth = 4;
        ctx.globalCompositeOperation = "destination-over";
        const detectedIndex = maxIndices[detection];
        const detectedClass = CLASSES[detectedIndex];
        const detectedScore = scores[detection];
        const dBox = boxes[detection];

        // No negative values for start positions
        const startY = dBox[0] > 0 ? dBox[0] * imgHeight : 0;
        const startX = dBox[1] > 0 ? dBox[1] * imgWidth : 0;
        const height = (dBox[2] - dBox[0]) * imgHeight;
        const width = (dBox[3] - dBox[1]) * imgWidth;
        ctx.strokeRect(startX, startY, width, height);
        // Draw the label background.
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = "#0B0";
        const textHeight = 16;
        const textPad = 4;
        const label = `${detectedClass} ${Math.round(detectedScore * 100)}%`;
        const textWidth = ctx.measureText(label).width;
        ctx.fillRect(startX, startY, textWidth + textPad, textHeight + textPad);
        // Draw the text last to ensure it's on top.
        ctx.fillStyle = "#000000";
        ctx.fillText(label, startX, startY);
      });

    console.log("Tensor Memory Status:", tf.memory().numTensors); //why still have tensor here
  }

  useEffect(() => {
    // Update the document title using the browser API
    // console.log("useeffect");
    performDetections();
  }, [files]);

  return (
    <section className="text-center my-5 ">
      <div
        {...getRootProps({
          className:
            "text-center cursor-pointer flex-none sm:flex justify-center", // not center when sm screen?
        })}
      >
        <input {...getInputProps()} />
        <img className="h-40 " src={landingImg} alt="addfile" />
        <img className="h-40 " src={addFileIcon} alt="addfile" />
      </div>
      <p className="text-xl font-semibold">
        Select or drag N drop your testing image (png/jpg/jpeg) here ☝️
      </p>
      {/* <div className="text-center cursor-pointer flex justify-center"> */}
      {isLoading && (
        <div className="flex justify-center">
          <Audio
            height="30"
            width="30"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class" // must set, other wise cause warning in console
          />
          <h1 className="text-xl font-bold text-center">loading</h1>
        </div>
      )}
      {/* </div> */}
      <div className="filesAccepted">
        {acceptedFiles[0] ? (
          <p className="text-green-500 text-2xl">
            {!isLoading
              ? detectnum !== 0
                ? detectnum === 1
                  ? `🙂 File accepted: ${detectnum} object is detected`
                  : `🙂 File accepted: ${detectnum} objects are detected`
                : "😉 File accepted: But No Object is detected, please try another image"
              : "🙂 File accepted, please wait..."}
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="fileNotAccepted">
        {fileRejections[0] ? (
          <Error errorM={fileRejections[0].errors[0]} />
        ) : (
          ""
        )}
      </div>
      <div className="flex justify-center flex-wrap ">
        {files.map((file) => (
          <div
            // className="image-preview m-2 p-4 w-40 h-40 "
            // style="position: relative"
            style={{ position: "relative" }}
            key={file.name}
          >
            <img
              src={file.preview}
              alt="thumnail"
              className="w-auto h-full rounded-md"
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
              id="gant"
            />
            <canvas
              id="detection"
              style={{ position: "absolute", left: 0, top: 0 }}
            ></canvas>
          </div>
        ))}
      </div>
    </section>
  );
}

export default DragNDrop;
