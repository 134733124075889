import React from "react";
import DragNDrop from "./components/DragNDrop";

const App = () => {
  return (
    <>
      <h1 className="text-xl font-bold text-center">
        AI objects detector, DongWang
      </h1>
      <DragNDrop />
      <h2 className="text-lg font-bold text-center font-semibold text-gray-900 dark:text-white">
        or Download sample images to test 👇
      </h2>
      <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
        <li>
          <a
            href="https://drive.google.com/uc?export=download&id=1zrksMUZkts1C252onQlu-ii4mzPmrjE7"
            className="mr-4 hover:underline md:mr-6 "
          >
            Office
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/uc?export=download&id=1K9m_XXl8Fj0A9VEi_WCJAQc1OMbJLgju"
            className="mr-4 hover:underline md:mr-6 "
          >
            Cat_01
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/uc?export=download&id=1IaHzDBH2m4SZgy6Hv6qpW0du2jTJIWtG"
            className="mr-4 hover:underline md:mr-6"
          >
            Cat_02
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/uc?export=download&id=1B70f1v2oRUywOai5ZjO8SXUnOFgCH8Lu"
            className="mr-4 hover:underline md:mr-6 "
          >
            Dog_01
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/uc?export=download&id=1Brtv3BGBqSp1qmBuXb_2SvC_dmGCCVHm"
            className="mr-4 hover:underline md:mr-6"
          >
            Dog_02
          </a>
        </li>
      </ul>
    </>
  );
};

export default App;
